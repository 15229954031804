import {Redirect, Route, Switch} from 'react-router-dom';
import React, {useMemo} from 'react';
import Global from '../config/global';
import MenuElementMapping, {MenuElement} from "./router-parse";
import {LoaderPage} from "./router-loader";

export default function RouterApp() {


    const router = useMemo(() => {
        const router = [];
        const entries = MenuElementMapping.mapping.entries()
        router.push(<Redirect key={Math.random()} exact from={'/'} to={'/invite'}/>);
        while (true) {
            const {value, done} = entries.next()
            if (done) {
                break
            }
            const menuElement: MenuElement = value[1]

            if (menuElement.type === "Link") {
                router.push(<Route key={Math.random()} exact path={menuElement.path}
                                   component={LoaderPage(menuElement)}/>);
            }
        }

        return router;
    }, []);


    return <Global.RouterType>
        <Switch>
            {router}
        </Switch>
    </Global.RouterType>;
}

