import {MenuElement} from "./router-parse";
import React from "react";
import AdminProgress from "../common/progress";

export function LoaderPage(element: MenuElement): React.ComponentType {
    return (props: any) => {
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<>   <AdminProgress/></>}>
            <Comp {...props} element={element}/>

        </React.Suspense>
    }
}

export function NonePage(element: MenuElement): React.ComponentType {
    return (props: any) => {
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<>   <AdminProgress/></>}>
            <Comp {...props} element={element}/>
        </React.Suspense>
    }
}


